const CategoriesList =[
    "general",
    "business",
    "entertainment",
    "health",
    "science",
    "sports",
    "technology",
]

export default CategoriesList;