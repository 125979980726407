import './css/Error404.css'

function Error404() {
    return (<>

        <div className="error404-container">
            <div className="error404-child">
                The Page You Are Looking For Does Not Exists
            </div>
        </div>

    </>)
}

export default Error404