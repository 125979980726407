const CountriesList = {
    in :"India(in)",
    ae :"United Arab Emirates (ae)",
    ar :"Argentina(ar)",
    at :"Austria(at)",
    au :"Australia(au)",
    be :"Belgium(be)",
    bg :"Bulgaria(bg)",
    br :"Brazil(br)",
    ca :"Canada(ca)",
    ch :"Switzerland(ch)",
    cn :"China(cn)",
    co :"Colombia(co)",
    cu :"Cuba(cu)",
    cz :"Czech(cz)",
    de :"Germany(de)",
    eg :"Egypt(eg)",
    fr :"France(fr)",
    gb :"United Kingdom(gb)",
    gr :"Greece(gr)",
    hk :"Hong Kong(hk)",
    hu :"Hungary(hu)",
    id :"Indonesia(id)",
    ie :"Ireland(ie)",
    il :"Isreal(il)",
    it :"Italy(it)",
    jp :"Japan(jp)",
    kr :"South Korea(kr)",
    lt :"Lithuania(lt)",
    lv :"Latvia(lv)",
    ma :"Morocco(ma)",
    mx :"Mexico(mx)",
    my :"Malaysia(my)",
    ng :"Nigeria(ng)",
    nl :"Netherlands(nl)",
    no :"Norway(no)",
    nz :"New Zealand(nz)",
    ph :"Phillipines(ph)",
    pl :"Poland(pl)",
    pt :"Portugal(pt)",
    ro :"Romania(ro)",
    rs :"Serbia(rs)",
    ru :"Russia(ru)",
    sa :"South Africa(sa)",
    se :"Sweden(se)",
    sg :"Singapore(sg)",
    si :"Slovenia(si)",
    sk :"Slovakia(sk)",
    th :"Thailand(th)",
    tr :"Turkey(tr)",
    tw :"Taiwan(tw)",
    ua :"Ukraine(ua)",
    us :"United States of America(us)",
    ve :"Venezuela(ve)",
}
export default CountriesList;