const NoOfNews = {

    12 : "12",
    20 : "20",
    30 : "30",
    40 : "40",
    50 : "50",
    4 : "Minimum",
    100 : "Maximum",
}

export default NoOfNews